import React from "react";
import "./styles.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import trophyimg from "../../images/school-awards/award-02.png";
import awardImg1 from "../../images/school-awards/award-04.png";
import awardImg2 from "../../images/school-awards/award-05.png";
import awardImg3 from "../../images/school-awards/award-06.png";
import awardImg4 from "../../images/school-awards/award-07.png";

const textData = [
  {
    text: "Ms. Archana Soni, Principal, Chinmaya Vidyalaya, New Delhi has been conferred with the prestigious State Teachers’ Award 2024 by Education Department, Govt. of NCT of Delhi. This recognition is a testament of her unwavering dedication, exceptional leadership and it highlights her significant impact on uplifting  the educational landscape of learning and teaching in Delhi.",
  },
  {
    text: "Chinmaya Vidyalaya, Vasant Vihar is Ranked No.1 in New Delhi in North India’s School Ranking under the category Most Effective Blended Learning Model in a survey conducted by Education Today. ",
  },
  {
    text: "Ms. Archana Soni, Principal, Chinmaya Vidyalaya, New Delhi, has been honoured with Education Stalwarts 2024 and Effective Principals of the year 2024 by Education Today for an exemplary contribution in the filed of education.",
  },

  {
    text: "Chinmaya Vidyalaya New Delhi has received the AAAA+ rating in the India’s Best Schools 2024 evaluation by the highly esteemed Careers360, for the third consecutive academic year. The remarkable achievement reflects the Vidyalaya’s unwavering commitment to excellence in education.",
  },
  {
    text: "Principal Ms Archana Soni has been recognised as the Most Iconic Principal for Excellence and Leadership in Education by Golden Aim Conference and Awards 2024.",
  },
  {
    text: "Chinmaya Vidyalaya is a recipient of overall trophy in the Shrimad Bhagwat Geeta Chanting Competition for the second consecutive year organised by Shankaracharya Sanskrit Mahavidyalaya, Bhartiya Vidya Bhawan.",
  },
  {
    text: "Chinmaya Vidyalaya, New Delhi was presented with the certificate of participation to recognise the contribution in breaking the Guinness World Record by collecting an astounding 437,975 kgs of paper waste. The collection drive was taken in collaboration with Blue Planet Environmental Solutions India Private Limited where over 300 schools participated.",
  },
  {
    text: `Chinmaya Vidyalaya has been adjudged to be among the Most Inspiring and Innovative Schools in multiple categories<br/> 
by ET TECH X School Excellence Awards 2023-24<br/>
By Brainfeed`,
  },
  {
    text: "Chinmaya Vidyalaya, New Delhi has been conferred with the Most Digitally Updated School (Delhi) at Star Education Award 2023 held by ESFE in collaboration with the Education Department of Maharashtra. ",
  },
  {
    text: "Chinmaya Vidyalaya, Vasant Vihar has been adjudged the 4th best school in South-West Delhi district by Times School Survey, 2023.",
  },
  {
    text: "Principal, Ms. Archana Soni, Chinmaya Vidyalaya, New Delhi has been honoured with 50 Effective Principals – 2023 Award for exemplary contribution in the field of education by Education Today.",
  },
  {
    text: "Education Today accorded Chinmaya Vidyalaya, New Delhi rank 1 for Unique Classroom Engagement Initiatives in New Delhi, in the CBSE Category in the North India’s School Merit Awards 2023. It is the recognition of Vidyalaya’s unflagging commitment towards excellence.",
  },
  {
    text: "Kudos Chinmaya Vidyalaya, New Delhi for outstanding performance. Chinmaya Vidyalaya, New Delhi is one of the 17 top schools that has been rated AAAA+ in the list of day schools in Delhi 2023 that comes under the National Day schools category by Careers360.",
  },
  {
    text: "On 13th March 2023, Chinmaya Vidyalaya received Spiritual and Value Education (SAVE) Award on the occasion of the Spiritual and Value Education Day in the Spiritual and Value Education Summit. Spiritual and Value Education Summit was jointly organised by The Educational Standards and Testing Council of India, International Association of Educators for World Peace, India Gandhi Technological and Medical Sciences University, Indian Institute of Ecology and Environment and Confederation of Indian Universities",
  },
  {
    text: "Chinmaya Vidyalaya is a recipient of the Happiness Award 2022-23 for exemplary leadership in promoting happiness, awarded by Society for Happiness.",
  },
  {
    text: "Chinmaya Vidyalaya ranked No.1 for “Holistic Education” in New Delhi, in the CBSE Category for The North India’s School Merit Awards. Survey Conducted by Education Today.",
  },
  {
    text: "Chinmaya Vidyalaya, New Delhi ranked 7th in ‘Leaders Category’, in South - West Delhi Zone, in ‘Times Survey 2022’. Awarded By: Times of India",
  },
  {
    text: "Of the 3000 rated schools by Careers360, only 50 best schools in India got the highest ratings of 5A. The next rating AAAA+ is earned by nearly 250 top schools in India and Chinmaya Vidyalaya, New Delhi was one of the AAAA+.",
  },
  {
    text: 'Chinmaya Vidyalaya received National Level Recognition for being “Top 50 Futuristic School of India" in 2022. Awarded By: Edu-Mirror the Educational Magazine Powered by Esteem International Ranking Pvt. Ltd',
  },
  {
    text: "Chinmaya Vidyalaya ranked 13 in India and ranked 3 in New Delhi for exemplary contribution in the field of Education under the category ‘India’s Top 20 CBSE Schools’ in a survey conducted by Education Today.",
  },
];

const imgData = [
  "https://chinmayavvdelhi.ac.in/assets/images/awards/0609241/pic3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/0409242/pic1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/0409242/pic2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/0409241/pic4.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/240520241/AWARD-India's-best-School-2024.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/230420241/pic1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/230420241/pic2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/feb-24-award-2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/feb-24-award-1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/Award-creative-for-website.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/star-award-pic-dec-23.png",
  "https://chinmayavvdelhi.ac.in/assets/images/press/times-school-survey-2023.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/principal-award-1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/principal-award-2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-5-sep-1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-5-sep-2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/INDIAS-BEST-SCHOOLS-2023.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-2-march-23.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-3-march-23.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/happinessaward4.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/accolades-14-dec-22.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/awards/accolades-14-dec-22-2.JPG",
  awardImg4,
  awardImg1,
  awardImg2,
  awardImg3,
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  responsive: [
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  arrows: true,
  responsive: [
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const AccoladesSection = () => {
  return (
    <div className="awards_new_section">
      <div className="awards_new_section__content">
        {/* <h2 className="awards_new_section__heading2">Accolades Won By the School</h2> */}
        <h1 className="heading--white">Chinmaya Vidyalaya New Delhi</h1>
        <p className="tagline">School with a difference</p> <br />
        <p>
          Chinmaya Vidyalaya, Vasant Vihar, New Delhi is a premier school in the
          heart of South Delhi, India.
          <br />
          <br />
          The school is affiliated with the CBSE, and is under the aegis of the
          Central Chinmaya Mission Trust Education Cell, as one of the flagship
          schools of the Chinmaya Education Movement. It is consistently ranked
          amongst the best schools in the country, and is renowned the world
          over for imparting value-based education.
          <br />
          <br />
          Over 10,000 students enrol each year, and more than a million students
          have graduated from the Chinmaya Vidyalaya network of schools.
        </p>
        <ul>
          {" "}
          <li>
            <i className="fa-solid fa-star"></i>
            <div>
              Ranked No. 4 in South West Delhi Leaders Category by Times School
              Survey 2023
            </div>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
            <div>Emphasis on culture and timeless Indian values</div>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
            <div>Amongst 17 top schools rated AAAA+ by Careers360</div>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
            <div>Excellent CBSE results year on year</div>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
            <div>Multitude of sports & co-scholastic activities</div>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
            <div>Lush green campus with state-of-the-art infrastructure</div>
          </li>
        </ul>
        <h2 className="awards_new_section__heading2">
          A glimpse of the accolades won by the school
        </h2>
        <p>
          ‘All our dreams can come true if we have the courage to pursue them.’
          Chinmaya Vidyalaya, New Delhi, in its pursuit of excellence and
          commitment to provide quality education to the 21st century learners,
          has been appreciated and applauded for its concerted efforts at
          various platforms.
        </p>
        <div className="row">
          <Slider {...settings2}>
            {textData.map((item, i) => (
              <div
                key={`slider-text-item-${i + 1}`}
                className="col col-12 col-lg-6"
              >
                <div className="card">
                  <img src={trophyimg}></img>
                  <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <br />
        <br />
        <div className="images">
          <Slider {...settings}>
            {imgData.map((item, i) => (
              <div key={`slider-img-item-${i + 1}`}>
                <img
                  className="award_image"
                  alt="awards image"
                  src={item}
                ></img>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AccoladesSection;
